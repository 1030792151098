<template>
  <div class="slider-wrap">
    <div class="slider-text-wrap">
      <div class="slider-text">{{ content }}</div>
      <el-input v-model="current" type="number" class="slider-input" @input="handleChange" />
    </div>
    <el-slider
      class="slider-progress"
      v-model="current"
      :min="0"
      :max="100"
      :step="1"
      :show-tooltip="false"
      :marks="marks"
      @change="handleChange"
    />
  </div>
</template>


<script>
export default {
  props: {
    content: {
      type: String,
      default: '选项',
    },
    maxScore: {
      type: Number,
      default: 100,
    },
    total: {
      type: Number,
      value: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      current: this.value,
      marks: {
        20: '20',
        40: '40',
        60: '60',
        80: '80',
      },
      score: 100,
    }
  },
  watch: {
    current(to) {
      this.current = +to
    },
    total(to) {
      // console.log('total', to)
      this.score = this.maxScore - to
    },
  },
  methods: {
    handleChange(to) {
      // console.log('score', this.score)
      // console.log('total', this.score + this.value)
      let total = this.score + this.value
      this.current = +to > total ? total : +to
      this.$emit('input', this.current)
    },
  },
}
</script>

<style lang="scss">
.slider-wrap {
  // width: 68%;
  // margin: 0 auto;
  .slider-text-wrap {
    display: flex;
    align-items: center;
    .slider-text {
      width: 200px;
    }
    .slider-input {
      width: 80px;
      margin-left: 20px;
    }
    .slider-progress {
      border-radius: 20%;
    }
  }
}
</style>