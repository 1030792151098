<template>
  <div class="survey-wrap">
    <div class="survey-bg"></div>
    <!-- <Slider /> -->
    <Swiper class="swiper-wrap" ref="mySwiper" :options="swiperOptions">
      <SwiperSlide v-for="(item, index) in questions" :key="item.questionId" class="swiper-item">
        <div class="swiper-detail">
          <div class="question-content">
            {{ index + 1 }}、{{ item.questionGroup }}：{{ item.questionContent }}
          </div>
          <div class="question-slider-tip" v-if="item.type == 'slider'">
            提示：总比重值必须为：{{ item.questionMaxScore }}，已分配比重：<span>{{
              item.scoreTotal
            }}</span>
          </div>
          <!-- 选择题 -->
          <template v-if="item.type == 'select'">
            <div
              class="question-option"
              :class="{ active: inner.check }"
              v-for="(inner, idx) in item.option"
              :key="inner.optionId"
              @click="selectAnswer(index, idx)"
            >
              <div class="question-option-select">{{ numMap[idx] }}、</div>
              <div class="question-option-content">
                <div style="white-space: pre-line">{{ inner.optionContent }}</div>
                <el-input
                  v-if="inner.optionUserFill == '1'"
                  class="question-option-remark"
                  v-model="inner.remark"
                  resize="none"
                />
              </div>
            </div>
          </template>

          <!-- 问答题 -->
          <template v-else-if="item.type == 'input'">
            <el-input
              type="textarea"
              rows="5"
              resize="none"
              placeholder="请输入回答"
              v-model="item.value"
              class="question-input"
            />
          </template>

          <!-- 比例分配题目 -->
          <div v-else-if="item.type == 'slider'" class="question-slider-wrap">
            <div v-for="inner in item.option" :key="inner.optionId" class="question-slider">
              <Slider
                :content="inner.optionContent"
                v-model="inner.remark"
                :total="+item.scoreTotal"
                :maxScore="+item.questionMaxScore"
                @input="handleSlider(index)"
              />
            </div>
          </div>

          <!-- 提交按钮 -->
          <div class="survey-btn-wrap" v-if="index === questions.length - 1">
            <el-button class="submit" @click="handleSubmit">提交</el-button>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <!-- <svg-icon class="icon iconfont" class-name="prev" icon-class="prev" /> -->
    <i class="iconfont icon-zuojiantou prev" @click="handlePrev"></i>
    <i class="iconfont icon-youjiantou next" @click="handleNext"></i>

    <el-dialog
      class="el-dialog-wrap"
      title="提示"
      :visible.sync="centerDialog"
      width="70%"
      :show-close="false"
    >
      <span>欢迎您在今年参与新员工培训及其它更多活动。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import request from '/src/utils/request.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Slider from '/src/components/Slider.vue'
export default {
  data() {
    return {
      centerDialog: false,
      questions: [], // typeId 1单选 2多选 3主观 4比例分配题
      numMap: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      checkedAnswer: [],
      swiperOptions: {
        direction: 'horizontal',
        width: window.innerWidth,
        // height: 300,
        // height: window.innerHeight,
        allowTouchMove: false, // 不允许触摸滑动
        // threshold : 120,
        autoHeight: true,
        on: {
          slideNextTransitionStart: (swiper) => {
            //向右或向下切换时，告诉我现在是第几个slide
            let activeIndex = swiper.activeIndex
            this.handleSwitch(swiper.activeIndex - 1)
          },
        },
      },
    }
  },
  components: {
    Slider,
    Swiper,
    SwiperSlide,
  },
  created() {
    request.post('/traningVote2021/getQuestions', { voteId: 25 }).then((res) => {
      if (res.data instanceof Array && !res.data.length) {
        this.centerDialog = true
      }
      this.changeData(res.data)
      // this.$refs.mySwiper.$swiper.slideTo(12, 1000, false)
    })
  },
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.centerDialog = false
      this.$router.replace('/home')
    },
    //滑块数值, 计算滑块总值
    handleSlider(index) {
      let question = this.questions[index]
      question.scoreTotal = 0
      question.option.forEach((ele) => {
        if (ele.remark) {
          question.scoreTotal += +ele.remark
        }
      })
    },
    // 调研提交
    handleSubmit() {
      // console.log(this.questions)
      let answers = {}
      this.questions.forEach((item) => {
        let index = item.questionId
        let answer = null
        // 单选
        if (item.typeId == '1') {
          let checkItem = item.option.filter((ele) => ele.check)[0]
          // console.log(checkItem)
          answer = {
            optionId: checkItem.optionId,
            remark: checkItem.optionUserFill == 1 ? checkItem.remark : '',
          }
        } else if (item.typeId == '2') {
          // 多选
          answer = item.option
            .filter((ele) => ele.check)
            .map((ele) => {
              return {
                optionId: ele.optionId,
                remark: ele.optionUserFill == 1 ? ele.remark : '',
              }
            })
        } else if (item.typeId == '3') {
          // 问答
          answer = item.value
        } else if (item.typeId == '4') {
          // 按比例分配
          answer = item.option.map((ele) => {
            return {
              optionId: ele.optionId,
              remark: ele.remark || 0,
            }
          })
        }
        answers[index] = { type: item.typeId, answer: answer }
      })
      request
        .post('/traningVote2021/submitAnswer', { voteId: 25, answers: JSON.stringify(answers) })
        .then((res) => {
          this.$router.replace({ path: 'finish', query: { type: 'survey' } })
        })
    },
    // 上一题
    handlePrev() {
      if (this.$refs.mySwiper.$swiper.activeIndex == 0) {
        this.$message.info('前面没有了')
        return
      }
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    // 下一题
    handleNext() {
      if (this.$refs.mySwiper.$swiper.activeIndex == this.questions.length - 1) {
        this.$message.info('已经是最后一题了')
        return
      }
      this.$refs.mySwiper.$swiper.slideNext()
    },
    // 下一题
    handleSwitch(index) {
      let question = this.questions[index]
      if (index < this.questions.length - 1) {
        if (+question.typeId === 3) {
          // 问答题
          if (!question.value) {
            this.$message.error('尚未作答')
            this.$refs.mySwiper.$swiper.slideTo(index, 1000, false)
          }
        } else if (+question.typeId === 4) {
          let score = 0
          question.option.forEach((ele) => {
            if (ele.remark) {
              score += +ele.remark
            }
          })
          if (score != question.questionMaxScore) {
            this.$message.error('请核对分配的比值总数')
            this.$refs.mySwiper.$swiper.slideTo(index, 1000, false)
          }
        } else if (+question.typeId < 3) {
          let remark = question.option.filter((ele) => ele.check && +ele.optionUserFill) || []
          if (question.option.filter((ele) => ele.check).length == 0) {
            this.$message.error('尚未选择')
            this.$refs.mySwiper.$swiper.slideTo(index, 1000, false)
          } else if (remark.length && remark.filter((ele) => ele.remark).length == 0) {
            this.$message.error('还有信息尚未填写')
            this.$refs.mySwiper.$swiper.slideTo(index, 1000, false)
          }
        }
      }
    },
    // 答题
    selectAnswer(index, idx) {
      let item = this.questions[index]
      let check = item.option[idx].check
      if (!check) {
        let filter = item.option.filter((ele) => ele.check)
        if (item.count > 1 && filter.length < item.count) {
          // 多选（在限选内）
          item.option[idx].check = 1
        } else if (item.count == 1) {
          // 单选
          item.option.forEach((ele) => {
            ele.check = 0
          })
          item.option[idx].check = 1
        } else {
          // 多选（超出限选数量）不能再选了
          this.$message.info('超出限选数量，不能再选了')
        }
      } else {
        item.option[idx].check = 0
      }
      // 调用强制更新方法this.$forceUpdate()会更新视图和数据，触发updated生命周期。
      this.$forceUpdate()
    },
    changeData(data) {
      for (var key in data) {
        let item = data[key]
        switch (+item.typeId) {
          case 1: // 单选
            item.type = 'select'
            item.count = 1
            break
          case 2: // 多选
            item.type = 'select'
            if (item.questionMaxAnswerQuantity) {
              // 限选答案个数
              item.count = +item.questionMaxAnswerQuantity || +item.option.length
            }
            break
          case 3: // 问答
            item.type = 'input'
            item.value = ''
            break
          case 4: // 滑块选择
            item.type = 'slider'
            item.scoreTotal = 0 // 当前滑块总和默认为0
            break
        }
        this.questions.push(item)
      }
    },
  },
}
</script>


<style lang="scss">
.survey-wrap {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .survey-bg {
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/img/survey1.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    position: fixed;
  }
  .swiper-wrap,
  .swiper-item {
    width: 100vw;
    // height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  .swiper-detail {
    width: 100vw;
    // height: 100%;
    overflow-y: auto;
    padding: 30px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .question-content {
      width: 68%;
      white-space: pre-line;
      font-size: 16px;
      line-height: 1.7;
      color: #333;
    }
    .question-option {
      margin-top: 12px;
      width: 68%;
      // height: 100vh;
      // height: 100%;
      background: #f2f2f2;
      border-radius: 6px;
      display: flex;
      box-sizing: border-box;
      padding: 20px;
      &.active {
        background: white;
        border: 2px solid #264c7f;
      }
      .question-option-select {
        font-size: 15px;
        line-height: 25px;
        color: #53585f;
      }
      .question-option-content {
        font-size: 15px;
        color: #53585f;
        width: 280px;
        line-height: 25px;
        white-space: pre-line;
        .question-option-remark {
          .el-input__inner {
            background: transparent;
            border: none;
            border-bottom: 1px solid #53585f;
          }
        }
      }
    }
    .question-input {
      margin-top: 12px;
      width: 68%;
      height: 132px;
      border-radius: 8px;
      border: none;
      font-size: 15px;
      .el-textarea__inner {
        background: #eee;
        border: none;
        font-size: 14px;
      }
    }
    .question-slider-wrap {
      width: 65%;
      .question-slider {
        margin: 15px 0;
        font-size: 15px;
      }
    }
    .question-slider-tip {
      // width: 68%;
      font-size: 12px;
      line-height: 1.5;
      margin: 10px 0;
      font-weight: bold;
      span {
        color: red;
      }
    }
  }
  .iconfont {
    position: fixed;
    top: 50%;
    font-size: 40px;
    color: #999;
    z-index: 100;
    opacity: 0.5;
    &.next {
      right: 0;
    }
  }
  .survey-btn-wrap {
    width: 68%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .submit {
      width: 70%;
      background: #007ec5;
      color: white;
    }
  }
  .el-dialog-wrap {
    margin-top: 30%;
  }
}
</style>